<template>
  <div class="login">
    <div class="login-form">
      <div class="mine-login-title">
        <span class="title-text-sub">安全生产信息化管理平台</span>

      </div>
      <form ref="formInline" class="form">
        <div class="form-item">
          <span class="form-item-icon">
            <img src="../assets/img/usr.png" alt="" class="img">
          </span>
          <div class="form-item-content">
            <input class="input" placeholder="请输入账号" v-model="username" @input="usrChange" />
            <span class="err_message" v-show="!username.length && showUsrMsg" v-text="usrErrMessage" />
          </div>
        </div>
        <div class="form-item">
          <span class="form-item-icon">
            <img src="../assets/img/pwd.png" alt="" class="img pwd">
          </span>
          <div class="form-item-content">
            <input class="input" placeholder="请输入密码" type="password" v-model="password" @input="pwdChange"  />
            <span class="err_message" v-show="!password.length && showPwdMsg" v-text="pwdErrMessage" />
<!--            <span class="err_message" v-show="password.length < 6 && showPwdMsg">密码不少于6位</span>-->
          </div>
        </div>
        <div class="form-item">
          <Button type="primary" long  class="login-btn"  @click="loginSys">
            登&nbsp;录
          </Button>
        </div>
      </form>
    </div>
    <div class="footer"> <span> Copyright © 2014-2019  内蒙古太平矿业有限公司 All Rights Reserved <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">蒙ICP备14004058号-2</a> </span></div>
  </div>
</template>

<script>
  const { mapActions, mapGetters } = window.Vuex;
  import { getAppConf } from '@/tools/utils';
  import { CLIENT_ID, CLIENT_SECRET } from "@/app.js";
  export default {
    data() {
      return {
        showUsrMsg: false,
        showPwdMsg: false,
        usrErrMessage: "请输入账号",
        pwdErrMessage: "请输入密码",
        username: "",
        password: ""
      };
    },
    computed: {
      ...mapGetters(["rootPath", "loginStatus"])
    },
    created () {
      this.rmSession();
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          if (!this.loginStatus) {
            this.loginSys()
          } else {
            return false;
          }
        }
      }
    },
    methods: {
      ...mapActions(["setSession", "setTenantId", "rmSession"]),
      ...mapActions("portal", ["login", "getRootPathByServer"]),
      usrChange(e) {
        this.showUsrMsg = true;
      },
      pwdChange(e) {
        this.showPwdMsg = true;
      },
      async loginSys() {
        if (!this.username.length || !this.password.length) {
          this.$Message.error("请输入帐号或密码");
          return false;
        }
        const appConfig = getAppConf();
        const tenant = this.$route.query.tenant || appConfig.tenant;
        this.setTenantId(tenant);
        await this.login({
          data: {
            tenant,
            grant_type: "password",
            username: this.username,
            password: this.password,
            client_id: appConfig.clientId || CLIENT_ID,
            client_secret: appConfig.clientSecret || CLIENT_SECRET
          }
        })
        this.$Message.success("登录成功");
      }
    }
  }
</script>

<style scoped lang="less">
  @base: 1.5;
  .login {
    position: relative;
    height: 100vh;
    background:
      url("../assets/img/login-flag.png") left top no-repeat,
      url("../assets/img/mine-bg.png") center center no-repeat;
    background-size: 700px auto, cover;
    .login-form {
      padding: 116px / @base 59px / @base 121px / @base;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.93);
      width: 579px / @base;
      position: absolute;
      right: 106px / @base;;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0px 4px / @base 10px / @base 0px #868686;
    }

    .mine-login-title {
      text-align: center;
    }

    .title-text {
      background: url("../assets/img/mine-logo.png") left center no-repeat;
      background-size: auto 100%;
      padding-left: 75px / @base;
      font-size: 42px / @base;
      line-height: 56px / @base;
      color: #333;
    }

    .title-text-sub {
      color: rgba(51, 51, 51, 1);
      font-size: 37px / @base;
      text-align: center;
      font-family: PingFangSC-Medium;
    }

    .welcome {
      font-size: 26px / @base;
      color: #9E9E9e;
    }

    .form {
      margin-top: 55px / @base;
      .form-item {
        display: flex;
        justify-content: space-between;
        height: 67px / @base;
        border-radius: 10px / @base;
        border: 1px solid rgba(173, 173, 173, 1);
        &:nth-child(2) {
          margin-top: 35px / @base;
        }
        &:nth-child(3) {
          margin-top: 71px / @base;
          background-color: transparent;
          border: 0 none;
        }
        .login-btn {
          height: 74px / @base;
          font-size: 24px / @base;
          background-color: rgba(0, 137, 255, 1);
          border-radius: 34px / @base;
        }
        .form-item-icon {
          width: 67px / @base;
          height: 67px / @base;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 20.75px/ @base;
            height: auto;

          }
        }
        .form-item-content {
          display: flex;
          flex: 1;
          position: relative;
          .input {
            flex: 1;
            height: 67px / @base;
            background-color: transparent;
            outline: none;
            border: 0 none;
          }
          .err_message {
            position: absolute;
            right: 30px / @base;
            text-align: right;
            top: 50%;
            transform: translateY(-50%);
            color: red;
            font-size: 16px / @base;
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 16px;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      span{
        background: rgba(11,52,41,0.72);
        color: #d9d9d9;
        font-size: 14px;
        padding: 10px 16px
      }
    }
  }
</style>
